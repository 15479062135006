
import { Component, Input, OnInit } from "@angular/core";
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormGroup
} from "@angular/forms";
import { errorsMessages } from "@config";
import { SelectOption } from "@interfaces";
import { NgSelectModule } from "@ng-select/ng-select";

@Component({
	selector: "boost-select",
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, NgSelectModule],
	template: `
		<div class="boost-select_form-group" [formGroup]="form">
		  <label>{{ label }}</label>
		
		  <ng-select
		    [labelForId]="label"
		    [items]="items"
		    bindLabel="label"
		    bindValue="value"
		    [placeholder]="label"
		    [clearable]="clearable"
		    [searchable]="false"
		    [formControlName]="controlName"
		    [multiple]="multiple"
		    appendTo="body"
		    >
		  </ng-select>
		
		  @if (
		    form.get(controlName)?.invalid &&
		    (form.get(controlName)?.touched || form.get(controlName)?.dirty)
		    ) {
		    <p
		      >
		      <span>{{ errorMessage() }}</span>
		    </p>
		  }
		</div>
		`
})
export class BoostSelectComponent implements OnInit {
	@Input() form!: UntypedFormGroup;
	@Input() controlName!: string;
	@Input() label!: string;
	@Input() clearable: boolean = true;
	@Input() items!: SelectOption[];
	@Input() multiple!: boolean;

	private control!: AbstractControl;
	public errorsMessages: any;
	public defaultMessage = errorsMessages["select"];

	ngOnInit() {
		// this.errorsMessages = errorsMessages[this.controlName ?? "default"];
		this.control = this.form.controls[this.controlName];
	}

	public errorMessage = (): string => {
		if (!this.errorsMessages) return this.defaultMessage;
		const controlErrors = this.control?.errors;
		if (!controlErrors) return "";
		const errorName = Object.keys(controlErrors)[0].toLowerCase();
		if (!errorName) return this.defaultMessage;
		return this.errorsMessages[errorName] || this.defaultMessage;
	};
}
