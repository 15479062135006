import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { BoConnection } from "@app/shared/interface";
import { BudgetinsightService } from "@src/app/core/services/API/budgetinsight.service";
import { StoreService } from "@src/app/core/services/store.service";
import { Observable } from "rxjs";
import { BankLogoComponent } from "../../components/bank-logo.component";
import { LoadingBoxComponent } from "../../components/loading-box.component";

@Component({
	standalone: true,
	imports: [CommonModule, LoadingBoxComponent, BankLogoComponent],
	selector: "connections-Issues",
	templateUrl: "connections-Issues.component.html",
	styleUrls: ["connections-Issues.component.scss"]
})
export class ConnectionsIssuesComponent {
	private BI = inject(BudgetinsightService);
	private STORE = inject(StoreService);
	public connectionsWithIssue$: Observable<BoConnection[]> =
		this.STORE.connectionsWithIssue$;

	public editConnection(): void {
		this.BI.openWebView();
	}
}
