import { Component, inject } from "@angular/core";
import { BudgetinsightService } from "@src/app/core/services/API/budgetinsight.service";

@Component({
	standalone: true,
	selector: "connection-issue-feedback",
	templateUrl: "./connection-issue-feedback.component.html",
	styleUrls: ["./connection-issue-feedback.component.scss"]
})
export class ConnectionIssueFeedbackComponent {
	private BI = inject(BudgetinsightService);
	public editConnection(): void {
		this.BI.openWebView();
	}
}
