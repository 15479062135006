@if ((connectionsWithIssue$ | async); as connectionsList) {
@if (connectionsList && connectionsList.length>0) {
<article>
  <header>
    <h1>Oups :/</h1>
  </header>
  <main>
    <p>
      Il est parfois nécessaire de confirmer une connexion pour des raisons de sécurité,
      pour continuez à profiter de tous les avantages BOOST épargne mettez à jour
      @if (connectionsList.length>1) {<span> ces connexions :</span>}
      @if (connectionsList.length<2) {<span> cette connexion :</span>}
    </p>
    <ul>
      @for (connection of connectionsList; track connection.id) {
      @if (connection.Bank; as bank) {
      <li>
        <bank-logo [bank]="bank" />
        <span>{{bank.name}}</span>
      </li>
      }
      }
    </ul>
    <a class="maj-button" (click)="editConnection()">
      <span>Mettre à jour les connexions</span>
    </a>
  </main>
</article>
} @else {

<div id="noConnectionIssues">
  <div>
    <p>Il est parfois nécessaire de confirmer une connexion pour des raisons de sécurité, mais pour
      l'instant&nbsp;: </p>
    <span class="material-symbols-rounded">verified</span>
    <strong> Tout est parfait</strong>
  </div>
</div>
}
} @else {
<loading-box />
}
